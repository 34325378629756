<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
    <div class="header-wrapper row m-0" style="place-content: flex-end;">
      <div class="header-logo-wrapper">
        <div class="logo-wrapper">
          <a routerLink='/'>
            <h1  class="primery-color-text">MCQ</h1>
          </a>
        </div>
        <div class="toggle-sidebar" >
          <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
        </div>
      </div>
      <div class="nav-right col-8 pull-right right-header p-0">
        <ul class="nav-menus">
          <!-- <li class="language-nav" >
            <div class="translate_wrapper" [class.active]="navServices.language">
              <div class="current_lang">
                <div class="lang">
                  <i class="flag-icon flag-icon-{{selectedLanguage.icon}}"></i><span
                    class="lang-txt">{{selectedLanguage.code}}</span>
                </div>
              </div>
              <div class="more_lang" [class.active]="navServices.language">
                <div class="lang selected" data-value="en" (click)="changeLanguage(lang)" *ngFor="let lang of languages">
                  <i class="flag-icon flag-icon-{{lang.icon}}"></i>
                  <span class="lang-txt">{{lang.language}}<span *ngIf="lang.type">({{lang.type}})</span></span>
                </div>
              </div>
            </div>
          </li> -->
          <!-- <li class="onhover-dropdown">
            <div class="notification-box" (click)="toggleNotificationMobile()">
              <app-feather-icons [icon]="'bell'"></app-feather-icons>
              <span class="badge badge-pill badge-secondary">4</span>
            </div>
            <ul class="notification-dropdown onhover-show-div" [class.active]="openNotification">
              <li>
                <app-feather-icons [icon]="'bell'"></app-feather-icons>
                <h6 class="f-18 mb-0">Notitications</h6>
              </li>
              <li>
                <p>
                  <i class="fa fa-circle-o mr-3 font-primary"></i>Delivery processing <span class="pull-right">10 min.</span>
                </p>
              </li>
              <li>
                <p>
                  <i class="fa fa-circle-o mr-3 font-success"></i>Order Complete<span class="pull-right">1 hr</span>
                </p>
              </li>
              <li>
                <p>
                  <i class="fa fa-circle-o mr-3 font-info"></i>Tickets Generated<span class="pull-right">3 hr</span>
                </p>
              </li>
              <li>
                <p>
                  <i class="fa fa-circle-o mr-3 font-danger"></i>Delivery Complete<span class="pull-right">6 hr</span>
                </p>
              </li>
              <li>
                <a class="btn btn-primary" href="#">Check all notification</a>
              </li>
            </ul>
          </li> -->
          <li class="profile-nav onhover-dropdown p-0">
            <div class="media profile-media">
              <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
              <div class="media-body">
                <span>{{profile?.firstName}}</span>
                <p class="mb-0 font-roboto">Profile <i class="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul class="profile-dropdown onhover-show-div">
              <li><a routerLink="../profile" [queryParams]="{profileid:profile.id, action:'edit'}"><app-feather-icons [icon]="'user'"></app-feather-icons><span>My Profile </span></a></li>
              <li><a routerLink="../profile" [queryParams]="{profileid:profile.id, action:'forgot'}"><app-feather-icons [icon]="'key'"></app-feather-icons><span>Change Password </span></a></li>
              <!-- <li><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
              <li><a  (click)="logout()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log Out</span></a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>