import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/firebase/auth.service';
import { CommonService } from '../../services/common.service';
import { HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show = false;
  public loginForm: FormGroup;
   LoginResult: any;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private loginservice: CommonService, ) {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
      });
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }

  login() {
    // this.spinner.show();
    const body = new HttpParams()
    .set('email', this.loginForm.value.email)
    .set('password', this.loginForm.value.password);
    this.loginservice.loginService(body, 'api/v1/login')
    .subscribe(response => {
      this.spinner.hide();
      this.LoginResult = response.body;
      if (this.LoginResult.success == true) {
        this.snackBar.open(this.LoginResult.message, 'x', {
          panelClass: ['success-snackbar'],
          duration: 5000
        });
        localStorage.setItem('token', JSON.stringify('Bearer ' + this.LoginResult.resp));
        localStorage.setItem('profile', JSON.stringify(this.LoginResult.data[0]));
        this.router.navigate(['.']).then(() => {
          window.location.reload();
          });
      } else {
        // this.snackBar.open(this.LoginResult.message, 'x', {
        //   panelClass: ['danger-snackbar'],
        //   duration: 5000
        // });
      }
    });
  }


}
