<app-breadcrumb [title]="'Dashboard'" [items]="[]" [active_item]="'Dashboard'"></app-breadcrumb>
<div class="container-fluid">
    <div class="row dash-chart">
        <div class="col-xl-6 box-col-6 col-md-6">
            <div class="card o-hidden">
                <div class="card-header card-no-border">
                    <h3>Category Wise Questions</h3>
                    <div class="card-header-right">
                        <ul class="list-unstyled card-option">
                            <li><i class="fa fa-spin fa-cog"></i></li>
                        </ul>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="media">
                        <div class="media-body">
                            <div class="profit-card">
                                <div id="spaline-chart">
                                    <ejs-accumulationchart id="container" #pie style='display:block;' [legendSettings]="legendSettings" [tooltip]="tooltip">
                                        <e-accumulation-series-collection>
                                            <e-accumulation-series name='Category' [dataSource]='ChartdataPackage' xName='x' yName='y' [dataLabel]="dataLabel"  >
                                            </e-accumulation-series>
                                        </e-accumulation-series-collection>
                                    </ejs-accumulationchart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 box-col-6 col-md-6">
            <div class="card o-hidden">
                <div class="card-header card-no-border">
                    <h3> Total Users</h3>
                    <div class="card-header-right">
                        <ul class="list-unstyled card-option">
                            <li><i class="fa fa-spin fa-cog"></i></li>
                        </ul>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="media">
                        <div class="media-body">
                            <div class="profit-card">
                                <div id="spaline-chart">
                                    <ejs-accumulationchart id="container1" #pie style='display:block;' [legendSettings]="legendSettings" [tooltip]="tooltip">
                                        <e-accumulation-series-collection>
                                            <e-accumulation-series name='User' [dataSource]='ChartdataUser' xName='x' yName='y' [dataLabel]="dataLabel"  >
                                            </e-accumulation-series>
                                        </e-accumulation-series-collection>
                                    </ejs-accumulationchart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 xl-50 box-col-12">
            <div class="card">
                <div class="card-header card-no-border">
                    <h5>Reports</h5>
                    <div class="card-header-right">
                        <ul class="list-unstyled card-option">
                            <li><i class="fa fa-spin fa-cog"></i></li>
                        </ul>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="our-product">
                        <div class="table-responsive">
                            <table class="table table-bordernone">
                                <tbody class="f-w-500">
                                    <tr *ngFor="let item of UserList.resp; let i =index">
                                        <td>
                                            <div class="media">
                                                <div class="media-body"><span>{{item.firstName}} {{item.lastName}} </span>
                                                    <p class="font-roboto">{{item.email}}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="btn btn-primary btn-sm mr-2" ngbTooltip="View" (click)="getHistoryData(item.id)">
                                                <i class="fa fa-eye"></i> View Test History </a>                                            
                                        </td>
                                    </tr>
                                    <tr class="media">
                                        <td><a routerLink="/report/individual-report">View All</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-6 xl-50 box-col-12">
            <div class="card">
                <div class="card-header card-no-border">
                    <h5>Flashcard</h5>
                    <div class="card-header-right">
                        <ul class="list-unstyled card-option">
                            <li><i class="fa fa-spin fa-cog"></i></li>
                        </ul>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="our-product">
                        <div class="table-responsive">
                            <table class="table table-bordernone">
                                <tbody class="f-w-500">
                                    <tr *ngFor="let item of flashcardList.resp; let i =index">
                                        <td>
                                            <div class="media">
                                                <div class="media-body"><span><p [appMath]="item.name"></p> </span>
                                                   <div class="d-flex"> <p class="font-roboto " [appMath]="item.categoryname"></p> - 
                                                    <p class="font-roboto" [appMath]="item.subcategoryname"></p></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="btn btn-primary btn-sm mr-2" ngbTooltip="View" (click)="setData(item)">
                                                <i class="fa fa-eye"></i> View</a>                                            
                                        </td>
                                    </tr>
                                    <tr class="media">
                                        <td><a routerLink="/master/flashcard-manage">View All</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-xl-6 xl-50 box-col-12">
            <div class="card">
                <div class="card-header card-no-border">
                    <h5>Flashcard</h5>
                    <div class="card-header-right">
                        <ul class="list-unstyled card-option">
                            <li><i class="fa fa-spin fa-cog"></i></li>
                        </ul>
                    </div>
                </div>
                <div class="card-body new-update pt-0">
                    <div class="table-responsive">
                        <table class="table table-bordernone">
                            <tbody class="f-w-500">
                                <tr *ngFor="let item of flashcardList?.resp; let i = index;" style="border-top: 1px solid #dee2e6;">
                                    <td>
                        <div class="media" >
                            <div class=""></div>
                            <div class="media-body"><span>{{item.name}}</span>
                                <p class="font-roboto" [appMath]="item.categoryname"></p>
                                <p class="font-roboto" [appMath]="item.description"></p>
                            </div>
                        </div>
                        </td>
                        </tr>
                        </tbody>
                        </table>
                        <div class="mt-3">
                            <a routerLink="/master/flashcard-manage" >View All</a>
                        </div>
                   </div>
                </div>
            </div>
        </div> -->
    </div>
</div>

