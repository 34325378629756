<div class="page-wrapper">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">     
          <div class="login-card">
            <div>
              <div>
                <a class="logo" routerLink='/'>
                    <h1  class="primery-color-text">MCQ</h1>
                </a>
              </div>
              <div class="login-main"> 
                <form class="theme-form" [formGroup]="forgetForm">
                  <h4>Reset Your Password</h4>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <div class="row">
                      <div class="col-12">
                        <input class="form-control" type="email" required="" placeholder="Email@yourmail.com" formControlName="email">
                        <div *ngIf="forgetForm.controls.email.touched && forgetForm.controls.email.errors?.required"
                            class="text text-danger mt-1">
                            Email is required
                        </div>
                        <div *ngIf="forgetForm.controls.email.touched && forgetForm.controls.email.errors?.email"
                            class="text text-danger mt-1">
                            Invalid Email
                        </div>
                      </div>
                      <div class="col-12">
                        <button class="btn btn-primary btn-block m-t-10" (click)="request()" type="submit">Send</button>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="text-center mt-4 mb-4"><span class="reset-password-link">If don't receive OTP?  <a class="btn-link text-danger" routerLink='/'>Resend</a></span></div> -->
                  
                  <!-- <h6 class="mt-4">Create Your Password</h6>
                  <div class="form-group">
                    <label class="col-form-label">New Password</label>
                    <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********">
                    <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Retype Password</label>
                    <input class="form-control" type="password" name="login[password]" required="" placeholder="*********">
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox">
                      <label class="text-muted" for="checkbox1">Remember password</label>
                    </div>
                    <button class="btn btn-primary btn-block" type="submit">Done</button>
                  </div> -->
                  <p class="mt-4 mb-0">Already have an password?<a class="ml-2" [routerLink]="'../auth/login'">Sign in</a></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>