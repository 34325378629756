import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../services/common.service';
import { AuthService } from '../services/firebase/auth.service';

@Component({
  selector: 'app-forget-pass',
  templateUrl: './forget-pass.component.html',
  styleUrls: ['./forget-pass.component.scss']
})
export class ForgetPassComponent implements OnInit {

  public show = false;
  public forgetForm: FormGroup;
  public errorMessage: any;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private loginservice: CommonService, ) {
      this.forgetForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]]
      });
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }

  request() {
    this.spinner.show();
    const body = new HttpParams()
    .set('email', this.forgetForm.value.email);
    this.loginservice.loginService(body, 'api/v1/forgetPassword')
    .subscribe(response => {
      this.spinner.hide();
      if (response.body.success == true) {
        this.snackBar.open(response.body.message, 'x', {
          panelClass: ['success-snackbar'],
          duration: 5000
        });
        // this.router.navigate(['']);
      } else {
        // this.snackBar.open(response.body.message, 'x', {
        //   panelClass: ['danger-snackbar'],
        //   duration: 5000
        // });
      }
    }, (err) => {
      this.spinner.hide();
      // this.snackBar.open(err.error.message, 'x', {
      //   panelClass: ['danger-snackbar'],
      //   duration: 5000
      // });
    });
  }
}
