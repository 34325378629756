import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { NavService } from 'src/app/services/nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  profile: any;
  public language = false;

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'Español',
    code: 'es',
    icon: 'es'
  },
  {
    language: 'Français',
    code: 'fr',
    icon: 'fr'
  },
  {
    language: 'Português',
    code: 'pt',
    type: 'BR',
    icon: 'pt'
  }];

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  };
  public openNotification = false;
  constructor(private translate: TranslateService,
              public router: Router,
              private loginservice: CommonService,
              public navServices: NavService) {
      this.profile = JSON.parse(localStorage.getItem('profile'));
     }

  ngOnInit() {
  }
  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }
  changeLanguage(lang) {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }
  logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    this.router.navigate(['/auth/login']).then(() => {
      window.location.reload();
      });
  }
}
