<app-breadcrumb [title]="'User'" [items]="[]" [active_item]="'Edit Profile'"></app-breadcrumb>
<div class="container-fluid">
  <div class="edit-profile">
    <div class="row">
      <div class="col-xl-8 xl-100">
        <div class="card">
          <div class="info market-tabs p-0 ">
            <div class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
              <ngb-tabset #tabset="ngbTabset" style="width: 100%;">
                <ngb-tab id="profile" *ngIf="newUser == 1">
                  <ng-template ngbTabTitle class="nav-item">Profile</ng-template>
                  <ng-template ngbTabContent class="tab-content">
                    <div class="tab-pane fade show gallery-popup" id="Profile" role="tabpanel"
                      aria-labelledby="Profile">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="card">
                            <div class="card-body"
                              *ngIf="(!parmData.profileid && parmData.action != 'editprofile') && parmData.action != 'newProfile'">
                              <form class="theme-form" [formGroup]="ProfileForm">
                                <div class="form-group">
                                  <label class="form-label">First Name</label>
                                  <input class="form-control" type="text" placeholder="First Name"
                                    formControlName="firstName" maxlength="50" pattern="[^'\@#$%^&**()!]+" title="Invalid input" onlyAlphabets>
                                  <div
                                    *ngIf="ProfileForm.controls.firstName.touched && ProfileForm.controls.firstName.errors?.required"
                                    class="text text-danger mt-1">
                                    Vaild First Name is required
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="form-label">Last Name</label>
                                  <input class="form-control" type="text" placeholder="Last Name"
                                    formControlName="lastName"  maxlength="50" pattern="[^'\@#$%^&**()!]+"  onlyAlphabets>

                                    <!-- <div *ngIf="ProfileForm.controls.lastName.touched  && !ProfileForm.controls.lastName.valid"  class="text text-danger mt-1">
                                      Vaild Phone Number is required
                                    </div> -->
                                </div>
                                <div class="form-group">
                                  <label class="form-label">Email</label>
                                  <input class="form-control" type="email" required="" placeholder="Email@gmail.com"
                                    formControlName="email">
                                  <div
                                    *ngIf="ProfileForm.controls.email.touched && ProfileForm.controls.email.errors?.required"
                                    class="text text-danger mt-1">
                                    Email is required
                                  </div>
                                  <div
                                    *ngIf="ProfileForm.controls.email.touched && ProfileForm.controls.email.errors?.email"
                                    class="text text-danger mt-1">
                                    Invalid Email
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="form-label">Phone Number</label>
                                  <input class="form-control" type="tel" placeholder="Phone Number"
                                  formControlName="phoneNumber" maxlength="15" pattern="[^'\x22@#$%^&**()!A-Za-z]+"  onlyAlphabets>

                                  <div *ngIf="ProfileForm.controls.phoneNumber.touched  && !ProfileForm.controls.phoneNumber.valid"  class="text text-danger mt-1">
                                    Vaild Phone Number is required
                                  </div>
                                </div>


                                <div class="form-group">
                                  <label class="form-label">Type</label>
                                  <select class="form-control" formControlName="type" disabled>
                                    <option value=""  selected>Select option</option>
                                    <option value="Normal">Normal</option>
                                    <option value="Subscribe">Subscribe</option>
                                    <!-- <option  value="Both" selected>Both</option> -->
                                  </select>

                                </div>
                                <div class="form-group">
                                  <label class="form-label">Status</label>
                                  <select class="form-control" formControlName="status">
                                    <option value=""  >Select option</option>
                                    <option value="0">Inactive</option>
                                    <option value="1" selected>Active</option>
                                  </select>

                                </div>
                                <div class="form-group">
                                  <label class="form-label">App Status</label>
                                  <select class="form-control" formControlName="appStatus">
                                    <option value="" >Select option</option>
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                  </select>

                                </div>
                                <div class="form-footer">
                                  <button class="btn btn-primary" (click)="requestProfile()" *ngIf="!IsUpdate"
                                    [disabled]="!ProfileForm.valid">Save</button>
                                  <button class="btn btn-primary" (click)="requestProfile()" *ngIf="IsUpdate"
                                  [disabled]="!ProfileForm.valid">Update</button>                                    
                                <button class="btn btn-danger ml-3" type="button" (click)="locationBack()">Cancel</button>
                                </div>
                              </form>
                            </div>
                            <div class="card-body"
                              *ngIf="parmData.profileid || parmData.action == 'editprofile' || parmData.action == 'newProfile'">
                              <form class="theme-form" [formGroup]="ProfileFormupdate">
                                <div class="form-group">
                                  <label class="form-label">First Name</label>
                                  <input class="form-control" type="text" placeholder="First Name"
                                    formControlName="firstName"   maxlength="50" pattern="[^'\22@#$%^&**()! 0-9]+" title="Invalid input" 
                                    >
                                    <div *ngIf="ProfileFormupdate.controls.firstName.touched  && !ProfileFormupdate.controls.firstName.valid"  class="text text-danger mt-1">
                                      Vaild First Name is required
                                    </div>
                                  <!-- <div
                                    *ngIf="ProfileFormupdate.controls.firstName.touched && ProfileFormupdate.controls.firstName.errors?.required"
                                    class="text text-danger mt-1">
                                    First Name is required
                                  </div> -->
                                </div>
                                <div class="form-group">
                                  <label class="form-label">Last Name</label>
                                  <input class="form-control" type="text" placeholder="Last Name"
                                    formControlName="lastName"  maxlength="50" pattern="[^'\22@#$%^&**()! 0-9]+" title="Invalid input" 
                                    >
                                    <div *ngIf="ProfileFormupdate.controls.lastName.touched  && !ProfileFormupdate.controls.lastName.valid"  class="text text-danger mt-1">
                                      Vaild Last Name is required
                                    </div>
                                </div>
                                <div class="form-group">
                                  <label class="form-label">Email</label>
                                  <input class="form-control" type="email" required="" placeholder="Email@gmail.com"
                                    formControlName="email">
                                  <div
                                    *ngIf="ProfileFormupdate.controls.email.touched && ProfileFormupdate.controls.email.errors?.required"
                                    class="text text-danger mt-1">
                                    Email is required
                                  </div>
                                  <div
                                    *ngIf="ProfileFormupdate.controls.email.touched && ProfileFormupdate.controls.email.errors?.email"
                                    class="text text-danger mt-1">
                                    Invalid Email
                                  </div>
                                </div>
                                <!-- <div class="form-group">
                                  <label class="form-label">Phone Number</label>
                                  <input class="form-control" type="number" placeholder="Phone Number" formControlName="phoneNumber">
                                  <div *ngIf="ProfileFormupdate.controls.phoneNumber.touched && ProfileFormupdate.controls.phoneNumber.errors?.required"
                                  class="text text-danger mt-1">
                                  Phone Number is required
                              </div>
                                </div> -->
                                <div class="form-group" *ngIf="parmData.action == 'newProfile'">
                                  <label class="form-label">Password</label>
                                  <input class="form-control" type="password" placeholder="Password"
                                    formControlName="password" >
                                  <div
                                    *ngIf="ProfileFormupdate.controls.password.touched && ProfileFormupdate.controls.password.errors?.required"
                                    class="text text-danger mt-1">
                                    Password is required
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="form-label">Status</label>
                                  <select class="form-control" formControlName="status">
                                    <option value=""  selected>Select option</option>
                                    <option [value]="0">Inactive</option>
                                    <option [value]="1" selected>Active</option>
                                  </select>

                                </div>
                                <div class="form-footer">
                                  <button class="btn btn-primary" (click)="addUserprofile()" *ngIf="!IsUpdate"
                                    [disabled]="!ProfileFormupdate.valid">Save</button>
                                  <button class="btn btn-primary" (click)="requestProfileupdate()" *ngIf="IsUpdate"
                                    [disabled]="!ProfileFormupdate.valid">Update</button>
                                    <button class="btn btn-danger ml-3" type="button" (click)="locationBack()">Cancel</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
                <ngb-tab id="newUser" *ngIf="newUser == 2">
                  <ng-template ngbTabTitle class="nav-item">Add User</ng-template>
                  <ng-template ngbTabContent class="tab-content">
                    <div class="tab-pane fade show gallery-popup" id="AddUser" role="tabpanel"
                      aria-labelledby="AddUser">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="card">
                            <div class="card-body">
                              <form class="theme-form" [formGroup]="AddUserForm">
                                <div class="form-group">
                                  <label class="form-label">First Name</label>
                                  <input class="form-control" type="text" placeholder="First Name"
                                    formControlName="firstName"    maxlength="50" pattern="[^'\22@#$%^&**()!0-9]+"  onlyAlphabets>

                                    <div *ngIf="AddUserForm.controls.firstName.touched  && !AddUserForm.controls.firstName.valid"  class="text text-danger mt-1">
                                      Vaild First Name is required
                                    </div>

                                    
                                  <!-- <div
                                    *ngIf="AddUserForm.controls.firstName.touched && AddUserForm.controls.firstName.errors?.required"
                                    class="text text-danger mt-1">
                                    First Name is required
                                  </div> -->
                                </div>
                                <div class="form-group">
                                  <label class="form-label">Last Name</label>
                                  <input class="form-control" type="text" placeholder="Last Name"
                                    formControlName="lastName"  maxlength="50" pattern="[^'\22@#$%^&**()!0-9]+"  onlyAlphabets>

                                    <div *ngIf="AddUserForm.controls.lastName.touched  && !AddUserForm.controls.lastName.valid"  class="text text-danger mt-1">
                                      Vaild Last Name is required
                                    </div>
                                </div>
                                <div class="form-group">
                                  <label class="form-label">Email</label>
                                  <input class="form-control" type="email" required="" placeholder="Email@gmail.com"
                                    formControlName="email">
                                  <div
                                    *ngIf="AddUserForm.controls.email.touched && AddUserForm.controls.email.errors?.required"
                                    class="text text-danger mt-1">
                                    Email is required
                                  </div>
                                  <div
                                    *ngIf="AddUserForm.controls.email.touched && AddUserForm.controls.email.errors?.email"
                                    class="text text-danger mt-1">
                                    Invalid Email
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="form-label">Phone Number</label>
                                  <input class="form-control" type="tel" placeholder="Phone Number"
                                    formControlName="phoneNumber" maxlength="50" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');" onlyAlphabets>

                                    <div *ngIf="AddUserForm.controls.phoneNumber.touched  && !AddUserForm.controls.phoneNumber.valid"  class="text text-danger mt-1">
                                      Vaild Phone Number is required
                                    </div>
                                </div>

                                <div class="form-group">
                                  <label class="form-label">Password</label>
                                  <input class="form-control" type="password" placeholder="Password"
                                    formControlName="password">
                                  <div
                                    *ngIf="AddUserForm.controls.password.touched && AddUserForm.controls.password.errors?.required"
                                    class="text text-danger mt-1">
                                    Password is required
                                  </div>
                                </div>

                                <div class="form-group">
                                  <label class="form-label">Type</label>
                                  <select class="form-control" formControlName="type">
                                    <option value=""  selected>Select option</option>
                                    <option value="Normal">Normal</option>
                                    <!-- <option value="Subscribers">Subscribers</option> -->
                                    <!-- <option  value="Both" selected>Both</option> -->
                                  </select>
                                </div>

                                <div class="form-group">
                                  <label class="form-label">Status</label>
                                  <select class="form-control" formControlName="status">
                                    <option value=""  selected>Select option</option>
                                    <option value="0">Inactive</option>
                                    <option value="1" selected>Active</option>
                                  </select>

                                </div>
                                <div class="form-group">
                                  <label class="form-label">App Status</label>
                                  <select class="form-control" formControlName="appStatus">
                                    <option value=""  selected>Select option</option>
                                    <option value="0">Inactive</option>
                                    <option value="1" selected>Active</option>
                                  </select>
                                </div>

                                <div class="form-footer">
                                  <button class="btn btn-primary" (click)="addUser()" *ngIf="!IsUpdate"
                                    [disabled]="!AddUserForm.valid">Save</button>
                                  <button class="btn btn-primary" (click)="addUser()" *ngIf="IsUpdate"
                                    [disabled]="!AddUserForm.valid">Update</button>
                                    <button class="btn btn-danger ml-3" type="button" (click)="locationBack()">Cancel</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
                <ngb-tab id="forgot" *ngIf="newUser == 3">
                  <ng-template ngbTabTitle class="nav-item">Change Password</ng-template>
                  <ng-template ngbTabContent class="tab-content">
                    <div class="tab-pane fade show gallery-popup" id="Change Password" role="tabpanel"
                      aria-labelledby="Change Password">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="card">
                            <div class="card-body">
                              <form class="theme-form" [formGroup]="requestForm">
                                <div class="form-group">
                                  <label class="form-label">Email</label>
                                  <input class="form-control" type="email" placeholder="email@domain.com" disabled
                                    formControlName="email">
                                  <div
                                    *ngIf="requestForm.controls.email.touched && requestForm.controls.email.errors?.required"
                                    class="text text-danger mt-1">
                                    Email is required
                                  </div>
                                  <div
                                    *ngIf="requestForm.controls.email.touched && requestForm.controls.email.errors?.email"
                                    class="text text-danger mt-1">
                                    Invalid Email
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="form-label">Password</label>
                                  <input class="form-control" type="password" value="password"
                                    formControlName="password">
                                  <div
                                    *ngIf="requestForm.controls.password.touched && requestForm.controls.password.errors?.required"
                                    class="text text-danger mt-1">
                                    Password is required
                                  </div>
                                </div>
                                <div class="form-footer">
                                  <button class="btn btn-primary" (click)="requestChange()"
                                    [disabled]="!requestForm.valid">Update</button>
                                    <button class="btn btn-danger ml-3" type="button" (click)="locationBack()">Cancel</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>