import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../services/common.service';
import { AuthService } from '../services/firebase/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public show = false;
  public registerForm: FormGroup;
  public errorMessage: any;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    public router: Router,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private loginservice: CommonService, ) {
      this.registerForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phoneNumber: ['', Validators.required],
        password: ['', Validators.required],
      });
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }

  register = () => {
    this.spinner.show();
    const body = new HttpParams()
    .set('firstName', this.registerForm.value.firstName)
    .set('lastName', this.registerForm.value.lastName)
    .set('email', this.registerForm.value.email)
    .set('phoneNumber', this.registerForm.value.phoneNumber)
    .set('password', this.registerForm.value.password);
    this.loginservice.loginService(body, 'api/v1/signupUser')
    .subscribe(response => {
      this.spinner.hide();
      if (response.body.success == true) {
        this.snackBar.open(response.body.message, 'x', {
          panelClass: ['success-snackbar'],
          duration: 5000
        });
        this.router.navigate(['../auth/login']);
      } else {
        this.snackBar.open(response.body.message, 'x', {
          panelClass: ['danger-snackbar'],
          duration: 5000
        });
      }
    }, (err) => {
      this.spinner.hide();
      // this.snackBar.open(err.error.message, 'x', {
      //   panelClass: ['danger-snackbar'],
      //   duration: 5000
      // });
    });
  }
}
