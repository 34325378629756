import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import {MatPaginatorModule} from '@angular/material/paginator';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { AuthService } from './services/firebase/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgetPassComponent } from './forget-pass/forget-pass.component';
import { RegisterComponent } from './register/register.component';
import { MainModule } from './main/main.module';
import { LoaderComponent } from './loader/loader.component';
import {MatSnackBarModule } from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MathModule } from './math/math.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/auth.guard';
// import { CarsReasonsComponent } from './main/master/cars-reasons/cars-reasons.component';
// import { EditCarsReasonsComponent } from './main/master/edit-cars-reasons/edit-cars-reasons.component';
// import { CarsReasonsComponent } from './master/cars-reasons/cars-reasons.component';
// import { EditCarsReasonsComponent } from './master/edit-cars-reasons/edit-cars-reasons.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const routes: Routes = [];
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetPassComponent,
    RegisterComponent,
    LoaderComponent,
    // CarsReasonsComponent,
    // EditCarsReasonsComponent
    ],
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    BrowserModule,
    FormsModule,
    CKEditorModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MainModule,
    NgxSpinnerModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    NgbTooltipModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,

    MatPaginatorModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    MathModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},
    AuthService,
    CookieService,
    LoaderComponent,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
