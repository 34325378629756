import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    dialogRef: any;
    constructor(
        private router: Router,

        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, ) {
        if (JSON.parse(localStorage.getItem('token'))) {
            // console.log('sss',localStorage.getItem('token'))
            return true;
        }else{
            this.router.navigateByUrl('auth/login');
            return false;
            // this.router.navigate(['auth/login']).then(() => {
            // window.location.reload();
            // });
            // return false;
        }
    }
}
