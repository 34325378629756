import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import * as chartData from '../../services/default';
import * as chartDatas from '../../services/ecommerce';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public greeting: string;
  public time: any;
  public today = new Date();
  public currentHour = this.today.getHours();
  public m = this.today.getMinutes();
  public ampm = this.currentHour >= 12 ? 'PM' : 'AM';
  public date: { year: number, month: number };

  public todayTotalSale = chartDatas.todayTotalSale;
  public totalVisit = chartDatas.totalVisit;
  public profit = chartDatas.profit;
  public riskFactor = chartDatas.riskFactor;
  // Charts
  public currentSales = chartData.currentSales;
  public smallBarCharts = chartData.smallBarCharts;
  public marketValue = chartData.marketValue;
  public knob = chartData.knob;
  public knobRight = chartData.knobRight;

  ResultData: any;
  deleteId: any;
  public flashCardForm: FormGroup;
  flashcardList: any = {};
  UserList: any = {};
  recordHistory: any = {};
  public ChartdataPackage: Object[] = [];
  public ChartdataPackage1: Object[] = [];
  public ChartdataUser: Object[] = [];
  public legendSettings: Object = {
    visible: false,
  };
  public dataLabel: Object = {
    visible: true,
    position: 'Inside', name: 'text',
    font: {
        fontWeight: '600'
    }
  };
  public tooltip: Object = { enable: true, format: '${point.x} : <b>${point.y}%</b>' };
  constructor(
    public router: Router,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private service: CommonService
  ) { }

  ngOnInit() {
    this.FlashcardListreport();
    this.getUserList();
    this.getpieData();
    this.startTime();
  }
  FlashcardListreport() {
    this.spinner.show();
    const offset = 0;
    const limit = 4;
    this.service.Servicetokenjson({}, 'api/v1/flashcardsLists?offset=' + offset + '&limit=' + limit)
    .subscribe(res => {
      this.spinner.hide();
      if (res.body.success == true) {
        this.flashcardList = res.body;
      } else {
        // this.snackBar.open(res.body.message, 'x', {
        //   panelClass: ['danger-snackbar'],
        //   duration: 5000
        // });
      }
    });
  }
  getpieData(){
    this.service.Servicetokenjson({}, 'api/v1/deshboardReport')
    .subscribe(res => {
      if (res.body.success == true) {
        const totalCat = res?.body?.respCategory.reduce(this.reducer, 0);
        // console.log(totalCat);
        const total = 0.0;

        res?.body?.respCategory.forEach((cat, i) => {
          this.ChartdataPackage1.push({ x: cat.name, y: this.widthcheck(cat.categoryQues, totalCat), text: cat.categoryQues });
        });
          // this.ChartdataPackage1.push({ "x": cat.name, y: cat.categoryQues, "text": this.widthcheck(cat.categoryQues, totalCat) + '%' })
          // });




        this.ChartdataPackage = this.ChartdataPackage1;

        this.ChartdataUser = [
            // {'x': 'Normal User', y: res?.body?.respUser[0].normalUser, text: ((res?.body?.respUser[0].normalUser * 100) / res?.body?.respUser[0].totalUser).toFixed(2) + '%'},
            // {'x': 'Subscribe User', y: res?.body?.respUser[0].subscribeUser, text: ((res?.body?.respUser[0].subscribeUser * 100) / res?.body?.respUser[0].totalUser).toFixed(2) + '%'}


            {x: 'Normal User', y: (res?.body?.respUser[0].normalUser * 100 / res?.body?.respUser[0].totalUser).toFixed(2), text: res?.body?.respUser[0].normalUser},



            {x: 'Subscribe User', y: (res?.body?.respUser[0].subscribeUser * 100 / res?.body?.respUser[0].totalUser).toFixed(2), text: res?.body?.respUser[0].subscribeUser}


          ];
        }
      });
  }

  setData(data){
    this.router.navigate(['master/edit-flashcard'], { queryParams: { id: data.id} });
  }
  reducer = (accumulator, currentValue) => accumulator + parseFloat(currentValue.categoryQues);
  widthcheck(a, b) {
    const value = ((a * 100) / b).toFixed(2);
    return value;
  }
  getUserList() {
    this.spinner.show();
    const offset = 0;
    const limit = 5;
    this.service.Servicetoken({}, 'api/v1/testAttendStudentLists?offset=' + offset + '&limit=' + limit )
    .subscribe(res => {
      this.spinner.hide();
      if (res.body.success == true) {
        this.UserList = res.body;
      } else {
        // this.snackBar.open(res.body.message, 'x', {
        //   panelClass: ['danger-snackbar'],
        //   duration: 5000
        // });
      }
    });
  }
  getHistoryData(id) {
    this.router.navigate(['/report/test-history'], { queryParams: { id} });
  }
  startTime() {
    this.currentHour = this.currentHour % 12;
    this.currentHour = this.currentHour ? this.currentHour : 12;
    this.m = this.checkTime(this.m);
    this.time = this.currentHour + ':' + this.m + ' ' + this.ampm;
  }

  checkTime(i) {
    if (i < 10) { i = '0' + i; }  // add zero in front of numbers < 10
    return i;
  }
}

