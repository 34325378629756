<div class="container-fluid p-0"> 
    <div class="row">
      <div class="col-12">     
        <div class="login-card">
          <div>
            <div>
              <a class="logo"  routerLink='/'>
                  <h1  class="primery-color-text">MCQ</h1>
              </a>
            </div>
            <div class="login-main"> 
              <form class="theme-form" [formGroup]="registerForm">
                <h4>Create your account</h4>
                <p>Enter your personal details to create account</p>
                <div class="form-group">
                  <label class="col-form-label pt-0">Your Name</label>
                  <div class="form-row">
                    <div class="col-6">
                      <input class="form-control" type="text" required="" placeholder="First name" formControlName="firstName">
                      <div *ngIf="registerForm.controls.firstName.touched && registerForm.controls.firstName.errors?.required"
                          class="text text-danger mt-1">
                          First Name is required
                      </div>
                    </div>
                    <div class="col-6">
                      <input class="form-control" type="text" required="" placeholder="Last name" formControlName="lastName">
                      <div *ngIf="registerForm.controls.lastName.touched && registerForm.controls.lastName.errors?.required"
                          class="text text-danger mt-1">
                          Last Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input class="form-control" type="email" required="" placeholder="Email@yourmail.com" formControlName="email">
                    <div *ngIf="registerForm.controls.email.touched && registerForm.controls.email.errors?.required"
                        class="text text-danger mt-1">
                        Email is required
                    </div>
                    <div *ngIf="registerForm.controls.email.touched && registerForm.controls.email.errors?.email"
                        class="text text-danger mt-1">
                        Invalid Email
                    </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Phone Number</label>
                  <input class="form-control" type="number" required="" placeholder="+91123456789" formControlName="phoneNumber">
                    <div *ngIf="registerForm.controls.phoneNumber.touched && registerForm.controls.phoneNumber.errors?.required"
                        class="text text-danger mt-1">
                        Phone Number is required
                    </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********">
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                </div>
                <div class="form-group mb-0">
                  <button class="btn btn-primary btn-block" type="button" (click)="register()">Create Account</button>
                </div>
                <p class="mt-4 mb-0">Already have an account?<a class="ml-2" [routerLink]="'../auth/login'">Sign in</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>