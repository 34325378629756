import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FeatherIconsComponent } from './feather-icons/feather-icons.component';
import { MainRoutingModule } from './main-routing.module';
import { ChartistModule } from 'ng-chartist';
import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CKEditorModule } from 'ngx-ckeditor';
import { CountToModule } from 'angular-count-to';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChartAllModule, AccumulationChartAllModule, RangeNavigatorAllModule } from '@syncfusion/ej2-angular-charts';
import { MathModule } from 'src/app/math/math.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DashboardComponent,
    FeatherIconsComponent,
    BreadcrumbComponent,
    ProfileComponent,
  ],
  imports: [
    MainRoutingModule,
    CommonModule,
    ChartistModule,
    ChartsModule,
    NgApexchartsModule,
    CarouselModule,
    CKEditorModule,
    ChartAllModule, RangeNavigatorAllModule, AccumulationChartAllModule,
    CountToModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MathModule,
    AgmCoreModule.forRoot({
      apiKey: ''
    })
  ],
  exports: [MainComponent, BreadcrumbComponent],
})
export class MainModule { }
