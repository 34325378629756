import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public show = false;
  public ProfileForm: FormGroup;
  public ProfileFormupdate: FormGroup;
  public AddUserForm: FormGroup;
  public requestForm: FormGroup;
  public errorMessage: any;
  ResultData: any;
  parmData: any;
  newUser: any;
  profiledata: any;
  IsUpdate = false;
  @ViewChild('tabset') tabset;


  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private loginservice: CommonService,
    private _location: Location, ) {
    this.profiledata = JSON.parse(localStorage.getItem('profile'));
    this.ProfileForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      type: ['', [Validators.required]],
      status: ['ACTIVE', [Validators.required]],
      appStatus: ['', [Validators.required]],
    });
    this.ProfileFormupdate = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      // phoneNumber: ['', [Validators.required]]
      status: ['ACTIVE'],
      password: [''],
    });
    this.AddUserForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      type: ['', [Validators.required]],
      status: ['', [Validators.required]],
      appStatus: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this.requestForm = this.fb.group({
      password: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });
  }
  ngAfterViewInit() {
    // console.log(this.profiledata);

    if (this.parmData.action) {
      if (this.parmData.action == 'forgot') {
        this.tabset.select('forgot');
        this.newUser = 3;
      } else if (this.parmData.action == 'new') {
        this.tabset.select('newUser');
        this.newUser = 2;
      } else if (this.parmData.action == 'edit' || this.parmData.action == 'editprofile' || this.parmData.action == 'newProfile') {
        this.tabset.select('profile');
        this.newUser = 1;
      }
    }
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {

      this.parmData = params;
      if (params.userId) {
        this.IsUpdate = true;
        this.getData(params.userId);
      }
      if (params.profileid) {
        this.IsUpdate = true;
        this.profile(this.profiledata.id);
      }
      if (this.parmData.action == 'editprofile' || this.parmData.user) {
        this.IsUpdate = true;
        this.profile(params.user);
      }
    });
  }
  profile(id) {
    this.spinner.show();
    this.profiledata = JSON.parse(localStorage.getItem('profile'));
    this.loginservice.Servicetoken({}, 'api/v1/viewCmsUserDetail?userId=' + id)
      .subscribe(res => {
        this.spinner.hide();
        if (res.body.success == true) {
          this.ResultData = res.body.resp[0];
          // localStorage.setItem('profile', JSON.stringify(this.ResultData));
          // this.updateProfileSeen();
          // console.log(this.ProfileFormupdate)
          this.ProfileFormupdate.get('firstName').setValue(res.body.resp[0].firstName);
          this.ProfileFormupdate.get('lastName').setValue(res.body.resp[0].lastName);
          this.ProfileFormupdate.get('email').setValue(res.body.resp[0].email);
          // this.ProfileFormupdate.get('phoneNumber').setValue(res.body.resp[0].phoneNumber);
          this.ProfileFormupdate.get('status').setValue(res.body.resp[0].status);

          if ((this.parmData.profileid || this.parmData.user) && this.parmData.action == 'forgot') {
            this.requestForm.get('email').setValue(res.body.resp[0].email);
            this.requestForm.get('password').setValue('');

          }
        } else {
          // this.snackBar.open(res.body.message, 'x', {
          //   panelClass: ['danger-snackbar'],
          //   duration: 5000
          // });
        }
      });
  }

  showPassword() {
    this.show = !this.show;
  }
  getData(id) {
    this.spinner.show();
    this.loginservice.Servicetoken({}, 'api/v1/viewUserDetail?userId=' + id)
      .subscribe(res => {
        this.spinner.hide();
        this.ResultData = res.body.resp[0];
        // console.log(this.ProfileForm)
        this.ProfileForm.get('firstName').setValue(res.body.resp[0].firstName);
        this.ProfileForm.get('lastName').setValue(res.body.resp[0].lastName);
        this.ProfileForm.get('email').setValue(res.body.resp[0].email);
        this.ProfileForm.get('phoneNumber').setValue(res.body.resp[0].phoneNumber);
        this.ProfileForm.get('type')?.setValue(res.body.resp[0].type);
        this.ProfileForm.get('status').setValue(res.body.resp[0].status);
        this.ProfileForm.get('appStatus').setValue(res.body.resp[0].appStatus);
        // console.log(this.ProfileForm)
        if (this.parmData.userId && this.parmData.action == 'forgot') {
          this.requestForm.get('email').setValue(res.body.resp[0].email);
          this.requestForm.get('password').setValue(res.body.resp[0].password);

        }

      });
  }
  requestProfile() {
    this.spinner.show();
    const body = new HttpParams()
      .set('id', this.parmData.userId)
      .set('firstName', this.ProfileForm.value.firstName)
      .set('lastName', this.ProfileForm.value.lastName)
      .set('email', this.ProfileForm.value.email)
      .set('phoneNumber', this.ProfileForm.value.phoneNumber)
      .set('type', '' + this.ProfileForm.value.type)
      .set('status', this.ProfileForm.value.status)
      .set('appStatus', this.ProfileForm.value.appStatus);
    this.loginservice.loginService(body, 'api/v1/updateUserDetail')
      .subscribe(response => {
        this.spinner.hide();
        if (response.body.success == true) {
          this.snackBar.open(response.body.message, 'x', {
            panelClass: ['success-snackbar'],
            duration: 5000
          });
          this._location.back();
        } else {
          // this.snackBar.open(response.body.message, 'x', {
          //   panelClass: ['danger-snackbar'],
          //   duration: 5000
          // });
        }
      }, (err) => {
        this.spinner.hide();
        // this.snackBar.open(err.error.message, 'x', {
        //   panelClass: ['danger-snackbar'],
        //   duration: 5000
        // });
      });
  }

  //   updateProfileSeen() {
  //     this.loginservice.updateProfileData();
  // }

  requestProfileupdate() {
    this.spinner.show();
    const body = new HttpParams()
      .set('firstName', this.ProfileFormupdate.value.firstName)
      .set('lastName', this.ProfileFormupdate.value.lastName)
      .set('email', this.ProfileFormupdate.value.email)
      // .set('phoneNumber', this.ProfileFormupdate.value['phoneNumber'])
      .set('status', this.ProfileFormupdate.value.status);
    this.loginservice.PostService(body, 'api/v1/updateCmsUserDetail')
      .subscribe(response => {
        this.spinner.hide();
        if (response.body.success == true) {
          this.snackBar.open(response.body.message, 'x', {
            panelClass: ['success-snackbar'],
            duration: 5000
          });
          if (this.parmData.action == 'edit' && this.parmData.profileid) {
            this.profiledata.firstName = this.ProfileFormupdate.value.firstName;
            this.profiledata.lastName = this.ProfileFormupdate.value.lastName;
            this.profiledata.email = this.ProfileFormupdate.value.email;
            this.profiledata.status = this.ProfileFormupdate.value.status;
            localStorage.setItem('profile', JSON.stringify(this.profiledata));
            this.router.navigate(['']).then(() => {
              window.location.reload();
            });
          } else {
            this._location.back();
          }
        } else {
          this.snackBar.open(response.body.message, 'x', {
            panelClass: ['danger-snackbar'],
            duration: 5000
          });
        }
      }, (err) => {
        this.spinner.hide();
        // this.snackBar.open(err.error.message, 'x', {
        //   panelClass: ['danger-snackbar'],
        //   duration: 5000
        // });
      });
  }

  addUserprofile() {
    this.spinner.show();
    const body = new HttpParams()
      .set('firstName', this.ProfileFormupdate.value.firstName)
      .set('lastName', this.ProfileFormupdate.value.lastName)
      .set('email', this.ProfileFormupdate.value.email)
      // .set('phoneNumber', this.ProfileFormupdate.value['phoneNumber'])
      .set('password', this.ProfileFormupdate.value.password)
      .set('status', this.ProfileFormupdate.value.status);
    this.loginservice.PostService(body, 'api/v1/signupCmsuser')
      .subscribe(response => {
        this.spinner.hide();
        if (response.body.success == true) {
          this.snackBar.open(response.body.message, 'x', {
            panelClass: ['success-snackbar'],
            duration: 5000
          });
          this._location.back();
        } else {
          this.snackBar.open(response.body.message, 'x', {
            panelClass: ['danger-snackbar'],
            duration: 5000
          });
        }
      }, (err) => {
        // console.log(err);

        this.spinner.hide();
        // this.snackBar.open(err.error.message, 'x', {
        //   panelClass: ['danger-snackbar'],
        //   duration: 5000
        // });
      });
  }

  makeRandom(lengthOfCode: number) {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  locationBack(){
    this._location.back();
  }
  addUser() {

    this.spinner.show();
    const body = new HttpParams()
      .set('firstName', this.AddUserForm.value.firstName)
      .set('lastName', this.AddUserForm.value.lastName)
      .set('email', this.AddUserForm.value.email)
      .set('phoneNumber', this.AddUserForm.value.phoneNumber)
      .set('type', this.AddUserForm.value.type)
      .set('status', this.AddUserForm.value.status)
      .set('appStatus', this.AddUserForm.value.appStatus)
      .set('password', this.AddUserForm.value.password);

    this.loginservice.loginService(body, 'api/v1/signupUser')
      .subscribe(response => {
        this.spinner.hide();
        if (response.body.success == true) {
          this.snackBar.open(response.body.message, 'x', {
            panelClass: ['success-snackbar'],
            duration: 5000
          });
          this._location.back();
          this.router.navigate(['']);
        } else {
          this.snackBar.open(response.body.message, 'x', {
            panelClass: ['danger-snackbar'],
            duration: 5000
          });
        }
      }, (err) => {
        this.spinner.hide();
        // this.snackBar.open(err.error.message, 'x', {
        //   panelClass: ['danger-snackbar'],
        //   duration: 5000
        // });
      });
  }
  requestChange() {
    this.spinner.show();
    let api = '';
    if ((this.parmData.profileid || this.parmData.user) && this.parmData.action == 'forgot') {
      api = 'api/v1/resetPassword';
    } else {
      api = 'api/v1/resetPasswordForAppUser';
    }
    const body = new HttpParams()
      .set('email', this.requestForm.value.email)
      .set('password', this.requestForm.value.password);
    this.loginservice.PostService(body, api)
      .subscribe(response => {
        this.spinner.hide();
        if (response.body.success == true) {
          this.snackBar.open(response.body.message, 'x', {
            panelClass: ['success-snackbar'],
            duration: 5000
          });
          if (this.parmData.profileid && this.parmData.action == 'forgot') {
            localStorage.removeItem('token');
            localStorage.removeItem('profile');
            this.router.navigate(['/auth/login']).then(() => {
              window.location.reload();
            });
          } else {
            this._location.back();
          }
        } else {
          this.snackBar.open(response.body.message, 'x', {
            panelClass: ['danger-snackbar'],
            duration: 5000
          });
        }
      }, (err) => {
        this.spinner.hide();
        // this.snackBar.open(err.error.message, 'x', {
        //   panelClass: ['danger-snackbar'],
        //   duration: 5000
        // });
      });
  }
}
