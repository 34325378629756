import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  baseUrl: any;
  baseUrluser: any;
  headers: any = '';
  constructor(
    public http: HttpClient,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService
  ) {
    // this.baseUrl = 'http://ADMIN-BACKEND-SERVER-URL/';
    // this.baseUrl = 'http://127.0.0.1:5001/';
    this.baseUrl = 'http://mcqadmin.artcodecloud.com:5000/';
    this.baseUrluser = 'http://USER-BACKEND-SERVER-URL/';
  }

  Servicetoken(saveData: any, currentUrl: any): Observable<any> {
    let currentToken = '';
    currentToken = localStorage.getItem('token');
    currentToken = currentToken.replace(/['"]+/g, '');
    const httpHeaders = new HttpHeaders({
      accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: currentToken
    });

    return this.http.get(this.baseUrl + currentUrl,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
      // catchError(err => {
      //   this.spinner.hide();
      //   this.snackBar.open(err.error.message, 'x', {
      //     panelClass: ['danger-snackbar'],
      //     duration: 5000
      //   });
      //   return throwError(err);
      // })
    );
  }
  ServicetokenPut(saveData: any, currentUrl: any): Observable<any> {
    let currentToken = '';
    currentToken = localStorage.getItem('token');
    currentToken = currentToken.replace(/['"]+/g, '');
    const httpHeaders = new HttpHeaders({
      accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: currentToken
    });

    return this.http.put(this.baseUrl + currentUrl,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
      // catchError(err => {
      //   this.spinner.hide();
      //   this.snackBar.open(err.error.message, 'x', {
      //     panelClass: ['danger-snackbar'],
      //     duration: 5000
      //   });
      //   return throwError(err);
      // })
    );
  }
  Servicetokenjson(saveData: any, currentUrl: any): Observable<any> {
    let currentToken = '';
    currentToken = localStorage.getItem('token');
    currentToken = currentToken.replace(/['"]+/g, '');
    const httpHeaders = new HttpHeaders({
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: currentToken
    });

    return this.http.get(this.baseUrl + currentUrl,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
      // catchError(err => {
      //   this.spinner.hide();
      //   this.snackBar.open(err.error.message, 'x', {
      //     panelClass: ['danger-snackbar'],
      //     duration: 5000
      //   });
      //   return throwError(err);
      // })
    );
  }

  postFile(fileToUpload: File): Observable<Blob> {
    let currentToken = '';
    currentToken = localStorage.getItem('token');
    currentToken = currentToken.replace(/['"]+/g, '');
    const endpoint = 'api/v1/uploadImage';
    const formData: FormData = new FormData();

    formData.append('banner', fileToUpload, fileToUpload.name);

    const httpHeaders = new HttpHeaders({
      Authorization: currentToken
    });

    return this.http.put<Blob>(this.baseUrl + endpoint, formData, {
      headers: httpHeaders
    });

  }
  Usertokenjson(saveData: any, currentUrl: any): Observable<any> {
    let currentToken = '';
    currentToken = localStorage.getItem('token');
    currentToken = currentToken.replace(/['"]+/g, '');
    const httpHeaders = new HttpHeaders({
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTAsImV4cGlyZSI6MTY1MzU2NDA5ODQ5MywiaWF0IjoxNjQ4MjkzNjk4fQ.aVZtVihHxL62D_ZX0YCQEKsiqSp-Vq_KHpN_S4j0aTI'
    });

    return this.http.get(this.baseUrluser + currentUrl,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
      // catchError(err => {
      //   this.spinner.hide();
      //   this.snackBar.open(err.error.message, 'x', {
      //     panelClass: ['danger-snackbar'],
      //     duration: 5000
      //   });
      //   return throwError(err);
      // })
    );
  }

  loginService(saveData: any, currentUrl: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: ''
    });

    return this.http.post(this.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          // this.spinner.hide();
          Swal.fire({
            icon: 'warning',
            title: err.error.message,
            timer: 2000,
            buttonsStyling: false,
            showConfirmButton: false
          });
          return throwError(err);
        })
        // catchError(err => {
        //       console.log(err);
        //       this.spinner.hide();
        //       this.snackBar.open(err.error.message, 'x', {
        //         panelClass: ['danger-snackbar'],
        //         duration: 5000
        //       });
        //       return throwError(err);
        //     })
      );
  }

  PostService(saveData: any, currentUrl: any): Observable<any> {
    let currentToken = '';
    currentToken = localStorage.getItem('token');
    currentToken = currentToken.replace(/['"]+/g, '');
    const httpHeaders = new HttpHeaders({
      accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: currentToken
    });
    // console.log(currentToken);
    return this.http.post(this.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
      // catchError(err => {
      //   this.spinner.hide();
      //   this.snackBar.open(err.error.message, 'x', {
      //     panelClass: ['danger-snackbar'],
      //     duration: 5000
      //   });
      //   return throwError(err);
      // })
    );
  }

  FormDatapostService(saveData: FormData, currentUrl: any): Observable<any> {
    let currentToken = '';
    currentToken = localStorage.getItem('token');
    currentToken = currentToken.replace(/['"]+/g, '');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: currentToken
    });
    return this.http.post(this.baseUrl + currentUrl, saveData,
      {
        observe: 'response'
      }).pipe(
      // catchError(err => {
      //   this.spinner.hide();
      //   this.snackBar.open(err.error.message, 'x', {
      //     panelClass: ['danger-snackbar'],
      //     duration: 5000
      //   });
      //   return throwError(err);
      // })
    );
  }
  imgservice(saveData, currentUrl: any): Observable<any> {
    let currentToken = '';
    currentToken = localStorage.getItem('token');
    currentToken = currentToken.replace(/['"]+/g, '');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      accept: 'application/json',
      Authorization: currentToken
    });
    return this.http.put(this.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
      // catchError(err => {
      //   this.spinner.hide();
      //   this.snackBar.open(err.error.message, 'x', {
      //     panelClass: ['danger-snackbar'],
      //     duration: 5000
      //   });
      //   return throwError(err);
      // })
    );
  }
  FormDataputService(saveData, currentUrl: any): Observable<any> {
    let currentToken = '';
    currentToken = localStorage.getItem('token');
    currentToken = currentToken.replace(/['"]+/g, '');
    const httpHeaders = new HttpHeaders({
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: currentToken
    });
    return this.http.put(this.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
      // catchError(err => {
      //   this.spinner.hide();
      //   this.snackBar.open(err.error.message, 'x', {
      //     panelClass: ['danger-snackbar'],
      //     duration: 5000
      //   });
      //   return throwError(err);
      // })
    );
  }

  singlegetService(currentUrl: any): Observable<any> {
    return this.http.get(this.baseUrl + currentUrl,
      {
        observe: 'response'
      }).pipe(
      // catchError(err => {
      //   this.spinner.hide();
      //   this.snackBar.open(err.error.message, 'x', {
      //     panelClass: ['danger-snackbar'],
      //     duration: 5000
      //   });
      //   return throwError(err);
      // })
    );
  }
}

