<ng-container *ngIf="!router.url.includes('login') && !router.url.includes('login') && !router.url.includes('/forget-pass')">
<div [ngClass]="layout.config.settings.layout_type" [class]="layout.config.settings.layout_version" >
  <div class="page-wrapper" id="canvas-bookmark" [ngClass]="layoutClass">
    <app-header></app-header>
    <div class="page-body-wrapper">
      <div class="sidebar-wrapper" [attr.sidebar-layout]="layout.config.settings.sidebar_type"
        [class.close_icon]="navServices.collapseSidebar">
        <app-sidebar></app-sidebar>
      </div>
      <div class="page-body">
          <router-outlet></router-outlet>
      </div>
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
    </div>
  </div>
</div>
</ng-container>

<ng-container *ngIf="router.url.includes('login') || router.url.includes('login') || router.url.includes('/forget-pass')">
  <router-outlet></router-outlet>
</ng-container>